import React from 'react'
import PropTypes from 'prop-types'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
          <span className="image main"><img src={pic01} alt="" /></span>
          <p>Kiai helps companies manage their web presence simply. Check out our <a href="#services">services</a>.</p>
          {close}
        </article>

        <article id="services" className={`${this.props.article === 'services' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Services</h2>
          <span className="image main"><img src={pic02} alt="" /></span>
          <p><strong>Web Content Analysis: </strong>A website audit is one of the most important parts of any content strategy. It’s also one of the most expensive and time-consuming – or rather, it was until Kiai. Our advanced web scraper can crawl and analyze your web content in a matter of hours, then deliver actionable insights to help you refine and improve the relevancy of your content. Find out what your audience is reading and what they’re skipping, what’s bringing in new readers and what’s bringing back the old ones. With Kiai, you won’t just be generating more traffic – you’ll be generating the right traffic.</p>
          <p><strong>Competive Gap Analysis: </strong>Kiai doesn’t just offer insight about your website. We’ll also use data derived from your web content to find your closest competitors and see what they’re up to. We’ll tell you the content topics that are working for them and let you know if you’re missing any important keywords that your audience wants to hear more about. We’ll also tell you how your content relevancy compares to the competition, helping you to become the most up-to-date source on your subject. So don’t get stuck wondering what everyone else in the business is up to – with Kiai, you’ll always stay be step ahead.</p>
          <p><strong>Trend Building: </strong>Social signals are more important than ever. That’s why Kiai aggregates current news and social media posts from all across the web to create a constantly growing index that will help you stay on top of current trends. Now you can know what ideas people are talking about before they’re talking about them – and with Kiai’s system of predictive models, you can learn what upcoming topics will drive the traffic you want to your site. With Kiai, you don’t need to guess what your visitors want to read – you can let us figure that out for you, so that you can focus on creating great content and keeping your audience more engaged than ever before.</p>
          <p><strong>Site Status Crawling: </strong>Keeping track of your site and sitemap are more important than ever. Kiai can intelligently observe your web pages to keep your users from reaching dead-ends or innactive content.</p>
          {close}
        </article>

        <article id="blog" className={`${this.props.article === 'blog' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Blog</h2>
          <span className="image main"><img src={pic03} alt="" /></span>
          <p>Check out <a href="https://blog.kiai.io">our blog</a>.</p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <form method="post" name="contact" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" required />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" required />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="https://twitter.com/kiaisoftware/" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a href="https://facebook.com/kiaisoftware/" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="https://github.com/Kiaisoftware" className="icon fa-github"><span className="label">GitHub</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool
}

export default Main
